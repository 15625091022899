<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />
    <!--? Hero Start -->
    <div class="slider-area2" style="height:200px;">
      <div class="slider-height2 hero-overly2 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap hero-cap2 text-center">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--? About Area Start-->
    <div class="support-company-area pt-100 section-bg fix">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-6">
            <div class="support-location-img">
              <img :src="imgSobre" alt="" style="border-radius: 15px;">
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="right-caption">
              <!-- Section Tittle -->
              <div class="about-details-cap">
                <span>{{ TitulopqSobre }}</span>
                <h4>{{ TituloGrSobre }}</h4>
              </div>
              <div class="support-caption sobrecolor">
                <p class="pera-top" v-html="Texto"></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- Hero End -->
    <div class="about-details section-padding30">
      <div class="container">
        <div class="row">
          <div class="col-lg-4" v-for="itemSobre in sobreitens" :key="itemSobre.institucionalId">
            <div class="about-details-cap mb-50">
              <h4>{{ itemSobre.nome }}</h4>
              <p v-html="itemSobre.resumo1"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CallActionPages />
  </div>
</template>

<script>
import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'

export default {
  components: {
    Header,
    CallActionPages
  },
  metaInfo() {
        return {
            title: 'About - Trevo International - Import and Export',
        }
    },
  data() {
    return {
      FundoMenu: '#000000',
      CorDefault: '',
      CaminhoDefault: '',
      sobretopo: [],
      TituloTopo: '',
      sobreitens: [],
      SobreHome: [],
      TitulopqSobre: '',
      TituloGrSobre: '',
      Texto: '',
      TxtBtn: '',
      LinkBtn: '',
      imgSobre: '',
    };
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/sobretopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.sobretopo = tiu2
        if (this.sobretopo != null) {
          this.TituloTopo = this.sobretopo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/sobreitens")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.sobreitens = tiu2.filter(x => x.visivel).sort(function (a, b) {
          return a.ordem - b.ordem;
        });
      });


    this.$http
      .get(this.$apiUrl + "/institucional/telas/sobretexto")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.SobreHome = tiu2
        if (this.SobreHome != null) {
          this.TitulopqSobre = this.SobreHome[0].nome;
          this.TituloGrSobre = this.SobreHome[0].referencia;
          this.Texto = this.SobreHome[0].resumo1;
          this.TxtBtn = this.SobreHome[0].textoBtn1;
          this.LinkBtn = this.SobreHome[0].linkBtn1;

          if (this.SobreHome[0].institucionalImg != null) {
            this.imgSobre = this.$apiUrlIndex + this.SobreHome[0].institucionalImg[0].urlImagem;
          }
        }
      });

  }
}
</script>

<style>
.sobrecolor p {
    font-size: 15px;
    color: #333333 !important;
}
</style>
