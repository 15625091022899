<template>

<section class="wantToWork-area w-padding2 section-bg" 
:style="{backgroundImage: 'url(' + imgCall + ')', backgroundPosition: 'center',borderBottom: '2px solid #cccccc'}">
        <div class="container">
            <div class="row align-items-center justify-content-between">
                <div class="col-xl-7 col-lg-9 col-md-8">
                    <div class="wantToWork-caption wantToWork-caption2">
                        <h2 v-html="Texto"></h2>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4">
                    <router-link :to="LinkBtn" class="btn btn-black f-right">{{TxtBtn}}</router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  components: { 
  },
   data() {
            return {
              CorDefault: '',
            CaminhoDefault:'',
            callaction:[],
            Texto:'',
            TxtBtn:'',
            LinkBtn:'',
            imgCall:'',
            }
        },
   created() {
    
    this.$http
      .get(this.$apiUrl + "/institucional/telas/callaction")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.callaction = tiu2
        if (this.callaction != null) {
          this.Texto = this.callaction[0].resumo1;
          this.TxtBtn = this.callaction[0].textoBtn1;
          this.LinkBtn = this.callaction[0].linkBtn1;

          if (this.callaction[0].institucionalImg != null) {
            this.imgCall = this.$apiUrlIndex + this.callaction[0].institucionalImg[0].urlImagem;
            console.log(this.imgCall)
          }
        }
      });

        },
}

</script>