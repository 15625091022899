<template>

<footer>
    <!--? Footer Start-->
    <div class="footer-area section-bg">
        <div class="container">
            <div class="footer-top footer-padding">
                <div class="row d-flex justify-content-between">
                    <div class="col-xl-6 col-lg-4 col-md-5 col-sm-8">
                        <div class="single-footer-caption mb-50">
                            <div class="footer-tittle">
                                <h4>{{TituloContato}}</h4>
                                <div class="row">
                                    <div class="col-xl-6">
                                        <span v-html="Texto1"></span>
                                    </div>
                                    <div class="col-xl-6">
                                        <span v-html="Texto2"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-2 col-md-3 col-sm-5">
                        <div class="single-footer-caption mb-50">
                            <div class="footer-tittle">
                                <h4>Links Úteis </h4>
                                <ul>
                                    <li v-for="item in ItensMenu" :key="item.institucionalId">
                                        <router-link :to="item.referencia">{{ item.nome }}</router-link>
                                    </li>
                                    <li v-for="itemInfo in ItensInfo" :key="itemInfo.institucionalId">
                                        <router-link
                                        :to="'/Information/' + itemInfo.institucionalId + '/' + itemInfo.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">{{
                                            itemInfo.nome
                                        }}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                        <div class="single-footer-caption mb-50">
                            <div class="footer-tittle">
                                <h4>Redes Sociais</h4>
                                <a v-if="instagram != ''" :href="instagram" target="_blank" style="padding-right: 5px;"><i class="fa fa-facebook-f" style="color: #ffffff;padding-right: 5px;"></i></a>
                                <a v-if="facebook != ''" :href="facebook" target="_blank" style="padding-right: 5px;"><i class="fa fa-instagram" style="color: #ffffff;padding-right: 5px;"></i></a>
                                <a v-if="linkedin != ''" :href="linkedin" target="_blank"><i class="fab fa-linkedin-in" style="color: #ffffff;padding-right: 5px;"></i></a>
                                <a v-if="youtube != ''" :href="youtube" target="_blank"><i class="fab fa-youtube" style="color: #ffffff;padding-right: 5px;"></i></a>
                                <a v-if="whatsapp != ''" :href="whatsapp" target="_blank"><i class="ab fa-whatsapp" style="color: #ffffff;padding-right: 5px;"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container col-xl-12 text-center" style="padding-top: 20px; padding-bottom: 20px;">
            <a href="https://avancedigital.com.br/" target="_blank">
                <img src="/assets/img/avanceBranco.png" alt=""></a> 
        </div>
    </div>
</footer>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
        CorDefault: '',
      CaminhoDefault: '',
      ItensMenu: [],
      ItensServicos: [],
      ItensInfo: [],
      Itensrodape: [],
      Contatos: '',
      Telefone: '',
      Email: '',
      redes: [],
      facebook: '',
      instagram: '',
      linkedin: '',
      youtube: '',
      whatsapp: '',
      LinkWhats: '',
      imgWhats: '',
      TituloTopo: '',
      DadosContato: [],
      TituloContato: '',
      Texto1:'',
      Texto2:'',
    };
  },
  methods: {
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/itensmenu")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensMenu = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/listainformações")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensInfo = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/redes")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.redes = tiu2;
        if (this.redes != null) {
          this.facebook = this.redes[0].nome;
          this.instagram = this.redes[0].referencia;
          this.linkedin = this.redes[0].campoExtra1;
          this.youtube = this.redes[0].campoExtra2;
          this.whatsapp = this.redes[0].campoExtra3;
          this.LinkWhats = this.redes[0].campoExtra4;
          if (this.redes[0].institucionalImg != null) {
            this.imgWhats = this.$apiUrlIndex + this.redes[0].institucionalImg[0].urlImagem;
          }
        }
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/dadoscontato")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.DadosContato = tiu2;
        if (this.DadosContato != null) {
          this.TituloContato = this.DadosContato[0].nome;
          this.Texto1 = this.DadosContato[0].resumo1;
          this.Texto2 = this.DadosContato[0].descricao1;
        }
      });
  }
}
</script>

<style>

span p {
    color: #bdbdbd;
    font-weight: 300;
}


</style>