<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />
    <div class="slider-area2" style="height:200px;">
      <div class="slider-height2 hero-overly2 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap hero-cap2 text-center">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  <!--? About Area Start-->
  <div class="support-company-area pt-100 section-bg fix" style="padding-bottom: 30px;">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-12 col-lg-12">
            <div class="support-location-img">
              <h3><a href="">{{ Info.nome }}</a></h3>
              <p v-html="Info.resumo1" style="text-align: justify;"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'

export default {
  components: {
    Header,
    //CallActionPages
  },
  data() {
    return {
      InstitucionalId: this.$route.params.id,
      FundoMenu: '#000000',
      CorDefault: '',
      CaminhoDefault: '',
      Topo: [],
      TituloTopo: '',
      ItensInformacoes: [],
      Info: {},
    };
  },
  metaInfo() {
    return {
      title: this.Info.nome + ' - Information - Trevo International - Import and Export',
    }
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/infotopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Topo = tiu2;
        if (this.Topo != null) {
          this.TituloTopo = this.Topo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/listainformações")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensInformacoes = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
        if (typeof (this.InstitucionalId) != 'undefined' || this.InstitucionalId != null) {
          this.Info = this.ItensInformacoes.filter(x => x.institucionalId == this.InstitucionalId)[0];
        }
        else {
          this.Info = this.ItensInformacoes[0];
        }
      });


  }
}
</script>

