<template>
    <div>
        <Header :corFundoMenu="FundoMenu" />
        <!--? Hero Start -->
        <div class="slider-area2" style="height:200px;">
            <div class="slider-height2 hero-overly2 d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="hero-cap hero-cap2 text-center">
                                <h2>{{ TituloTopo }}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Hero End -->
        <!-- ================ contact section start ================= -->
        <section class="contact-section">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-tittle mb-70">
                            <span>{{ TituloPqservicos }}</span>
                            <h2>{{ TituloGrServicos }}</h2>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <form class="form-contact contact_form" id="contactForm" novalidate="novalidate">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <textarea v-model="Mensagem" class="form-control w-100" name="message" id="message"
                                            cols="30" rows="9" onfocus="this.placeholder = ''"
                                            onblur="this.placeholder = 'Enter Message'" placeholder="Message"></textarea>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <input v-model="Nome" class="form-control valid" name="name" id="name" type="text"
                                            onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'"
                                            placeholder="Name">
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="form-group">
                                        <input v-model="Email" class="form-control valid" name="email" id="email"
                                            type="email" onfocus="this.placeholder = ''"
                                            onblur="this.placeholder = 'Enter email address'" placeholder="Email">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <input v-model="Telefone" class="form-control" name="subject" id="subject"
                                            type="text" onfocus="this.placeholder = ''"
                                            onblur="this.placeholder = 'Enter Telephone'" placeholder="Telephone">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-3">
                                <a href="#" class="button button-contactForm boxed-btn" @click="Enviar()">Submit
                                    Message</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Header from '../shared/Header.vue'

export default {
    components: {
        Header,
    },
    metaInfo() {
        return {
            title: 'Contact - Trevo International - Import and Export',
        }
    },
    methods: {
        Enviar() {
            if (this.Nome == '' || this.Telefone == '' || this.Email == '' || this.Mensagem == '') {
                this.$mensagem_normal("Fill in all fields");
            } else {
                let tmp = { ToEmail: this.Contato.Destinatario, Subject: 'Contact Site', Body: "Nome: " + this.Nome + " | Telefone: " + this.Telefone + " | E-mail: " + this.Email + " | Mensagem:  " + this.Mensagem };
                this.$http
                    .post(
                        this.$apiUrl + "/contato/enviamail/" + this.Nome, tmp
                    )
                    .then(
                        (response) => {
                            if (response.ok) {
                                this.Nome = '';
                                this.Telefone = '';
                                this.Email = '';
                                this.Mensagem = '';
                                this.$redirect_reload("/contactSend");
                            }
                        },
                        (error) => {
                            console.log(error);
                            this.Resposta = error.bodyText;
                        }
                    );
            }
        },
    },
    data() {
        return {
            FundoMenu: '#000000',
            servicostopo: [],
            TituloTopo: '',
            Servicos: [],
            TituloGrServicos: '',
            TituloPqservicos: '',
            DadosContato: [],
            TituloContato: '',
            Nome: '',
            Email: '',
            Telefone: '',
            Mensagem: '',
            Contato: {
                NomeEmpresa: "",
                Assunto: "Contact",
                Destinatario: "",
                EmailResposta: "",
                TextoEmail: ""
            },
            TextoEndereco: '',
        };
    },
    created() {
        this.$http
            .get(this.$apiUrl + "/institucional/telas/contatotopo")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.servicostopo = tiu2
                if (this.servicostopo != null) {
                    this.TituloTopo = this.servicostopo[0].nome;
                }
            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/contatochamada")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.Servicos = tiu2
                if (this.Servicos != null) {
                    this.TituloGrServicos = this.Servicos[0].nome;
                    this.TituloPqservicos = this.Servicos[0].referencia;
                }
            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/dadoscontato")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.DadosContato = tiu2;
                if (this.DadosContato != null) {
                    this.TituloContato = this.DadosContato[0].nome;
                    this.Contato.Destinatario = this.DadosContato[0].referencia;
                    this.Texto1 = this.DadosContato[0].resumo1;
                    this.Texto2 = this.DadosContato[0].descricao1;
                }
            });

    }
}
</script>

