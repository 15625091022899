<template>

  <div>
    <Header :corFundoMenu="FundoMenu" />

 <!--? Hero Start -->
 <div class="slider-area2" style="height:200px;">
      <div class="slider-height2 hero-overly2 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap hero-cap2 text-center">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero End -->
    <!--? Categories Area Start -->
    <div class="team-area section-padding30">
      <div class="container">
        <div class="row">
          <div v-for="itensProd in ListaServicos" :key="itensProd.institucionalId"
            class="col-xl-2 col-lg-2 col-md-4 col-sm-2 col-6">
            <div class="single-team mb-30">
              <div class="team-img">
                  <img v-if="itensProd.institucionalImg != ''"
                    :src="$apiUrlIndex + itensProd.institucionalImg[0].urlImagem" :alt="itensProd.nome" :title="itensProd.nome"
                    style="border-radius: 15px;width: 70%;">
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
  
<script>
import Header from '../shared/Header.vue'
//import LightBox from 'vue-it-bigger'
//import CallActionPages from '../shared/CallActionPages.vue';

export default {
  components: {
    Header,
    //LightBox,
   // CallActionPages
  },
  metaInfo() {
        return {
            title: 'Products - Trevo International - Import and Export',
        }
    },
  data() {
    return {
      FundoMenu: '#000000',
      mostraGaleria: false,
      urlImg: '',
      CorDefault: '',
      CaminhoDefault: '',
      Servicos: [],
      TituloGrServicos: '',
      TituloPqservicos: '',
      ListaServicos: [],
      servicostopo: [],
      TituloTopo: '',
    };
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/parceirostopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.servicostopo = tiu2
        if (this.servicostopo != null) {
          this.TituloTopo = this.servicostopo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/listaparceiros")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ListaServicos = tiu2.filter(x => x.visivel).sort(function (a, b) {
          return a.ordem - b.ordem;
        });
      });
  }
}
</script>
  