<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />

    <section class="hero-wrap hero-wrap-2"
      :style="{ backgroundImage: 'url(' + $CaminhoImages + CaminhoDefault + '/sobre/sobretopo.jpg' + ')' }">
      <div class="overlay"></div>
      <div class="container">
        <div class="row no-gutters slider-text align-items-center justify-content-center">
          <div class="col-md-9  text-center">
            <h1 class="mb-2 bread">Blog</h1>
          </div>
        </div>
      </div>
    </section>



    <main id="main">

      <!--==========================
  Services Section
============================-->
      <section id="services" style="padding-top: 100px;padding-bottom: 30px;">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <h3>TÍTULO BLOG</h3>
              <p style="text-align: justify;">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.<br/> <br/>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                ex ea commodo consequat.</p>
            </header>
          </div>
          <div class="row" style="text-align: center;">
            <a type="" href="javascript:window.history.go(-1)" style="color:#000000;">Voltar</a>
          </div>
        </div>

      </section><!-- #services -->
      <CallActionPages />

    </main>

  </div>
</template>

<script>
import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'

export default {
  components: {
    Header,
    CallActionPages
  },
  data() {
    return {
      FundoMenu: '#000000',
      perPage: 3,
      currentPage: 1,
      items: [],
      rows: 1,
    };
  },
  created() {

  }
}
</script>


