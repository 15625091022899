<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />
        <!--? Hero Start -->
        <div class="slider-area2" style="height:200px;">
            <div class="slider-height2 hero-overly2 d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="hero-cap hero-cap2 text-center">
                                <h2>Blog</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Hero End -->

    <section class="blog_area section-padding">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 mb-5 mb-lg-0">
            <div class="blog_left_sidebar">
              <article class="blog_item">
                <div class="blog_item_img">
                  <img class="card-img rounded-0" :src="$CaminhoImages + CaminhoDefault + '/blog/noticia1.jpg'" alt="">
                </div>
                <div class="blog_details">
                  <a class="d-inline-block" href="">
                    <h2>Título da Notícia</h2>
                  </a>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ultricies orci id nunc imperdiet, ut
                    imperdiet libero fringilla. Vivamus eget erat mattis, tempus nisi ac, dignissim lectus. Lorem ipsum
                    dolor sit amet, consectetur adipiscing elit.</p>
                  <ul class="blog-info-link"></ul>
                </div>
              </article>
              <nav class="blog-pagination justify-content-center d-flex">
                <ul class="pagination">
                  <li class="page-item">
                    <a href="#" class="page-link" aria-label="Previous">
                      <i class="ti-angle-left"></i>
                    </a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link">1</a>
                  </li>
                  <li class="page-item active">
                    <a href="#" class="page-link">2</a>
                  </li>
                  <li class="page-item">
                    <a href="#" class="page-link" aria-label="Next">
                      <i class="ti-angle-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="blog_right_sidebar">
              <aside class="single_sidebar_widget search_widget">
                <form action="#">
                  <div class="form-group">
                    <div class="input-group mb-3">
    
                      <aside class="single_sidebar_widget popular_post_widget">
                        <h3 class="widget_title">Notícias Recentes</h3>
                        <div class="media post_item">
                          <img :src="$CaminhoImages + CaminhoDefault + '/blog/noticia2.jpg'" alt="post" style="width: 100px;">
                          <div class="media-body">
                            <router-link to="/blog">
                              <h3>Título Notícia</h3>
                            </router-link>
                          </div>
                        </div>
                        <div class="media post_item">
                          <img :src="$CaminhoImages + CaminhoDefault + '/blog/noticia3.jpg'" alt="post" style="width: 100px;">
                          <div class="media-body">
                            <router-link to="/blog">
                              <h3>Título Notícia</h3>
                            </router-link>
                          </div>
                        </div>
                        <div class="media post_item">
                          <img :src="$CaminhoImages + CaminhoDefault + '/blog/noticia1.jpg'" alt="post" style="width: 100px;">
                          <div class="media-body">
                            <router-link to="/blog">
                              <h3>Título Notícia</h3>
                            </router-link>
                          </div>
                        </div>
                        <div class="media post_item">
                          <img :src="$CaminhoImages + CaminhoDefault + '/blog/noticia2.jpg'" alt="post" style="width: 100px;">
                          <div class="media-body">
                            <router-link to="/blog">
                              <h3>Título Notícia</h3>
                            </router-link>
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>
                </form>
              </aside>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'

export default {
  components: {
    Header,
    //CallActionPages
  },
  data() {
    return {
      CorDefault: '',
      CaminhoDefault: '',
      FundoMenu: '#000000',
      perPage: 3,
      currentPage: 1,
      items: [],
      rows: 1,
    };
  },
  created() {
    let dadosArmazenados = sessionStorage.getItem(this.$ModeloSite);
    if (dadosArmazenados != null && dadosArmazenados.length > 0) {
      dadosArmazenados = JSON.parse(dadosArmazenados);
      this.CorDefault = dadosArmazenados.CorDefault;
      this.CaminhoDefault = dadosArmazenados.CaminhoDefault;
    }
  }
}
</script>

<style>

</style>

