<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />
    <div class="slider-area2" style="height:200px;">
      <div class="slider-height2 hero-overly2 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap hero-cap2 text-center">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section id="about" style="padding-top: 60px;padding-bottom: 30px;text-align: center;">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="section-tittle mb-70">
              <span>{{ TituloPqservicos }}</span>
              <h2>{{ TituloGrServicos }}</h2>
            </div>
          </div>
          <div class="col-12">
            <p v-html="texto"></p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Header from '../shared/Header.vue'
export default {
  components: {
    Header
  },
  metaInfo() {
        return {
            title: 'Contact Send - Trevo International - Import and Export',
        }
    },
  data() {
    return {
      FundoMenu: '#000000',
      Topo: [],
      TituloTopo: '',
      TextoContato: [],
      TituloGrServicos: '',
      TituloPqservicos: '',
      texto: '',
            
    };
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/contatotopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Topo = tiu2;
        if (this.Topo != null) {
          this.TituloTopo = this.Topo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/contatoenviado")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.TextoContato = tiu2;
        if (this.TextoContato != null) {
          this.TituloGrServicos = this.TextoContato[0].nome;
          this.TituloPqservicos = this.TextoContato[0].referencia;
          this.texto = this.TextoContato[0].resumo1;
        }
      });
  }
}
</script>

