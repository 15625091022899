<template>
  <div>
    <!--==========================
    Intro Section
  ============================-->

    <Header :corFundoMenu="FundoMenu" />
    <SliderTopo />

    <!-- Team Start -->
    <div class="team-area section-padding30">
      <div class="container">
        <div class="row">
          <div class="cl-xl-7 col-lg-8 col-md-10">
            <!-- Section Tittle -->
            <div class="section-tittle mb-70">
              <span>{{ TituloPq }}</span>
              <h2>{{ TituloGr }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- single Tem -->
          <div v-for="itensProd in ListaProdutos" :key="itensProd.institucionalId"
            class="col-xl-4 col-lg-3 col-md-4 col-sm-6">
            <div class="single-team mb-30">
              <div class="team-img">
                <router-link
                  :to="'/productsDetail/' + itensProd.institucionalId + '/' + itensProd.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">
                  <img v-if="itensProd.institucionalImg != ''"
                    :src="$apiUrlIndex + itensProd.institucionalImg[0].urlImagem" :alt="itensProd.nome"
                    style="border-radius: 15px;">
                </router-link>
              </div>
              <router-link
                :to="'/productsDetail/' + itensProd.institucionalId + '/' + itensProd.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">
                <div class="team-caption">
                  <h3>{{ itensProd.nome }}</h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Team End -->
    <!--? About Area Start-->
    <div class="support-company-area pt-100 pb-100 section-bg fix"
      style="background-Image: url('/assets/img/gallery/section_bg02.jpg')">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-6">
            <div class="support-location-img">
              <img :src="imgSobre" alt="">
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="right-caption">
              <!-- Section Tittle -->
              <div class="section-tittle section-tittle2 mb-50">
                <span>{{ TitulopqSobre }}</span>
                <h2>{{ TituloGrSobre }}</h2>
              </div>
              <div class="support-caption">
                <p class="pera-top" v-html="Texto"></p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="categories-area section-padding30">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- Section Tittle -->
            <div class="section-tittle mb-70">
              <span>{{ TituloPqservicos }}</span>
              <h2>{{ TituloGrServicos }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-for="itemServ in ListaServicos" :key="itemServ.institucionalId" class="col-lg-4 col-md-6 col-sm-6">
            <router-link
                    :to="'/servicesDetail/' + itemServ.institucionalId + '/' + itemServ.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">
            <div class="single-cat text-center mb-50">
              <div class="cat-cap">
                <h5><strong>{{ itemServ.nome }}</strong></h5>
                <p v-html="itemServ.resumo1"></p>
              </div>
            </div>
          </router-link>
          </div>
        </div>
      </div>
    </div>
    <CallActionPages />

  </div>
</template>

<script>
import SliderTopo from '../shared/SliderTopo.vue'
//import ClientesSlider from '../shared/ClientesSlider.vue'
//import Depoimentos from '../shared/Depoimentos.vue'
import Header from '../shared/Header.vue'
import CallActionPages from '../shared/CallActionPages.vue'

export default {
  components: {
    SliderTopo,
    // ClientesSlider,
    //Depoimentos,
    Header,
    CallActionPages
  },
  metaInfo() {
        return {
            title: 'Home - Trevo International - Import and Export',
        }
    },
  data() {
    return {
      FundoMenu: "",
      CorDefault: '',
      CaminhoDefault: '',
      Topo: [],
      TituloGr: '',
      TituloPq: '',
      ListaProdutos: [],
      SobreHome: [],
      TitulopqSobre: '',
      TituloGrSobre: '',
      Texto: '',
      TxtBtn: '',
      LinkBtn: '',
      imgSobre: '',
      Servicos: [],
      TituloGrServicos: '',
      TituloPqservicos: '',
      ListaServicos: [],
    };
  },
  methods: {
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/produtoschamada")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Topo = tiu2
        if (this.Topo != null) {
          this.TituloGr = this.Topo[0].nome;
          this.TituloPq = this.Topo[0].referencia;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/listaprodutos")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ListaProdutos = tiu2.filter(x => x.visivel).sort(function (a, b) {
          return a.ordem - b.ordem;
        });
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/sobrehome")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.SobreHome = tiu2
        if (this.SobreHome != null) {
          this.TitulopqSobre = this.SobreHome[0].nome;
          this.TituloGrSobre = this.SobreHome[0].referencia;
          this.Texto = this.SobreHome[0].resumo1;
          this.TxtBtn = this.SobreHome[0].textoBtn1;
          this.LinkBtn = this.SobreHome[0].linkBtn1;

          if (this.SobreHome[0].institucionalImg != null) {
            this.imgSobre = this.$apiUrlIndex + this.SobreHome[0].institucionalImg[0].urlImagem;
          }
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/servicoschamada")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Servicos = tiu2
        if (this.Servicos != null) {
          this.TituloGrServicos = this.Servicos[0].nome;
          this.TituloPqservicos = this.Servicos[0].referencia;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/listaservicos")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ListaServicos = tiu2.filter(x => x.visivel).sort(function (a, b) {
          return a.ordem - b.ordem;
        });
      });


  }
}
</script>