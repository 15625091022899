<template>
    <div class="slider-area ">
    <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators fade img-width="1024"
      img-height="380" style="text-shadow: 1px 1px 1px #000;">
      <!-- Slides with custom text -->
      <b-carousel-slide class="single-slider d-flex align-items-center" v-for="item in slide.slice(0,10)" :key="item.institucionalId"  :img-src="$apiUrlIndex + item.institucionalImg[0].urlImagem">
        <div class="container">
          <div class="row">
            <div class="col-xl-8 col-lg-7 col-md-8">
              <div class="hero__caption">
                <span>{{item.nome}}</span>
                <h1 v-html="item.resumo1"></h1>
              </div>
            </div>
          </div>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sliding: null,
      CorDefault: '',
      CaminhoDefault: '',
      slide:[],
    };
  },
  methods: {},
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/slide")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.slide = tiu2.filter(x => x.visivel).sort(function (a, b) {
          return a.ordem - b.ordem;
        });
      });
  },
}
</script>

<style>
.carousel-caption {
  position: absolute;
  right: 5% !important;
  bottom: 1.25rem;
  top: 60px;
  left: 5% !important;
  padding-top: 3.25rem !important;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: left !important;
}

.home-slider .slider-item .slider-text h1 {
    font-size: 60px;
    color: #fff;
    line-height: 1.2;
    font-weight: 900;
}

@media (max-width:1200px) {

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    top: 0px;
    left: 15%;
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }
}

@media (max-width:767px) {
    .hidden-xs {
        display: none !important;
    }

    .ajustafontecel{
      font-size: 18px !important;
    }
}
</style>