<template>

  <div>
    <Header :corFundoMenu="FundoMenu" />

 <!--? Hero Start -->
 <div class="slider-area2" style="height:200px;">
      <div class="slider-height2 hero-overly2 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap hero-cap2 text-center">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero End -->
    <!--? Categories Area Start -->
    <div class="team-area section-padding30">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- Section Tittle -->
            <div class="section-tittle mb-70">
              <span>{{ TituloPqservicos }}</span>
              <h2>{{ TituloGrServicos }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-for="itensProd in ListaServicos" :key="itensProd.institucionalId"
            class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
            <div class="single-team mb-30">
              <div class="team-img">
                <router-link
                  :to="'/productsDetail/' + itensProd.institucionalId + '/' + itensProd.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">
                  <img v-if="itensProd.institucionalImg != ''"
                    :src="$apiUrlIndex + itensProd.institucionalImg[0].urlImagem" :alt="itensProd.nome"
                    style="border-radius: 15px;">
                </router-link>
              </div>
              <router-link
                :to="'/productsDetail/' + itensProd.institucionalId + '/' + itensProd.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">
                <div class="team-caption">
                  <h3>{{ itensProd.nome }}</h3>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>
  
<script>
import Header from '../shared/Header.vue'
//import LightBox from 'vue-it-bigger'
//import CallActionPages from '../shared/CallActionPages.vue';

export default {
  components: {
    Header,
    //LightBox,
   // CallActionPages
  },
  metaInfo() {
        return {
            title: 'Products - Trevo International - Import and Export',
        }
    },
  data() {
    return {
      FundoMenu: '#000000',
      mostraGaleria: false,
      urlImg: '',
      CorDefault: '',
      CaminhoDefault: '',
      Servicos: [],
      TituloGrServicos: '',
      TituloPqservicos: '',
      ListaServicos: [],
      servicostopo: [],
      TituloTopo: '',
    };
  },
  created() {
    this.$http
      .get(this.$apiUrl + "/institucional/telas/produtostopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.servicostopo = tiu2
        if (this.servicostopo != null) {
          this.TituloTopo = this.servicostopo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/produtoschamada")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Servicos = tiu2
        if (this.Servicos != null) {
          this.TituloGrServicos = this.Servicos[0].nome;
          this.TituloPqservicos = this.Servicos[0].referencia;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/listaprodutos")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ListaServicos = tiu2.filter(x => x.visivel).sort(function (a, b) {
          return a.ordem - b.ordem;
        });
      });
  }
}
</script>
  