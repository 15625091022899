<template>
  <div id="app">

    <transition name="fadeIn">
      <router-view :key="$route.fullPath"></router-view>
    </transition>

     <Footer /> 

  </div>
</template>

<script>

  import Footer from './components/site/shared/Footer.vue'
  export default {
    name: 'App',
    components: {
      Footer 
    },
    data() {
      return {
        colour:'',
        CorDefault: '',
        CaminhoDefault:'',
        DadosCompra: {
          CorDefault:'',
          CaminhoDefault:''
        }     
      }
    },
    methods: {
      ValidaCor(){
        var css = document.createElement('style');
        css.type = 'text/css';
        var estilos = ":root { --main-bg-color: " + this.CorPrincipal + "}";
        css.appendChild(document.createTextNode(estilos));
        document.getElementsByTagName("head")[0].appendChild(css);
      }
    },
    created() {     
      
      this.$http
                .get(this.$apiUrl + "/institucional/telas/logotopo")
                .then((res2) => res2.json())
                .then((tiu2) => {
                    this.Retorno = tiu2;
                    if (this.Retorno != null) {
                        this.CorPrincipal = this.Retorno[0].cor1;
                        this.CorSecundaria = this.Retorno[0].cor2;
                        this.ValidaCor();
                    }

                });
    },
    beforeDestroy () {
      sessionStorage.removeItem(this.$ModeloSite);
    },
  }
</script>

<style>
/*   :root {
  --main-bg-color: 'v-bind(colour)';
} 
 */
  /* css tmp */
  @import '/assets/css/bootstrap.min.css';
  @import '/assets/css/owl.carousel.min.css';
  @import '/assets/css/slicknav.css';
  @import '/assets/css/flaticon.css';
  @import '/assets/css/animate.min.css';
  @import '/assets/css/magnific-popup.css';
  @import '/assets/css/fontawesome-all.min.css';
  @import '/assets/css/themify-icons.css';
  @import '/assets/css/slick.css';
  @import '/assets/css/nice-select.css';
  @import '/assets/css/style.css';
 
  /*---------- Animations */

  .page-item.active .page-link {
    z-index: 3;
    color: #fff ;
    background-color: var(--main-bg-color) !important;
    border-color: var(--main-bg-color) !important;
}

b, strong {
    font-weight: 800 !important;
}

  p {
    margin-top: 0;
    margin-bottom: 0rem !important;
}

  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

 a {
   text-decoration: none !important;
 }

 ol, ul {
    padding-left: 0rem !important;
}

 .owl-theme .owl-dots .owl-dot span {
    width: 0px !important;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    transition: opacity 200ms ease;
    border-radius: 30px;
}

@media (max-width:767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }
    .nav-menu > li {
    margin-left: 0px !important;
}
    
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }
}
</style>
