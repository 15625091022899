<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />

    <!--? Hero Start -->
    <div class="slider-area2" style="height:200px;">
      <div class="slider-height2 hero-overly2 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap hero-cap2 text-center">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero End -->
    <!--? Categories Area Start -->
    <div class="categories-area section-padding30">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <!-- Section Tittle -->
            <div class="section-tittle mb-70">
              <span>{{ TituloPqservicos }}</span>
              <h2>{{ TituloGrServicos }}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-for="itemServ in ListaServicos" :key="itemServ.institucionalId" class="col-lg-4 col-md-6 col-sm-6">
            <router-link
                    :to="'/servicesDetail/' + itemServ.institucionalId + '/' + itemServ.nome.replaceAll(' ', '-').replaceAll('#', '').replaceAll('/', '-')">
            <div class="single-cat text-center mb-50">
              <div class="cat-icon">
                <span :class="itemServ.campoExtra1"></span>
              </div>
              <div class="cat-cap">
                <h5><strong>{{ itemServ.nome }}</strong></h5>
                <p v-html="itemServ.resumo1"></p>
              </div>
            </div>
          </router-link>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
//import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'

export default {
  components: {
    Header,
    //CallActionPages
  },
  metaInfo() {
        return {
            title: 'Services - Trevo International - Import and Export',
        }
    },
  data() {
    return {
      FundoMenu: '#000000',
      CorDefault: '',
      CaminhoDefault: '',
      Servicos: [],
      TituloGrServicos: '',
      TituloPqservicos: '',
      ListaServicos: [],
      servicostopo: [],
      TituloTopo: '',
    };
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/servicostopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.servicostopo = tiu2
        if (this.servicostopo != null) {
          this.TituloTopo = this.servicostopo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/servicoschamada")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Servicos = tiu2
        if (this.Servicos != null) {
          this.TituloGrServicos = this.Servicos[0].nome;
          this.TituloPqservicos = this.Servicos[0].referencia;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/listaservicos")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ListaServicos = tiu2.filter(x => x.visivel).sort(function (a, b) {
          return a.ordem - b.ordem;
        });
      });
  }
}
</script>

