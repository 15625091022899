<template>
  <div>
    <Header :corFundoMenu="FundoMenu" />

    <!--? Hero Start -->
    <div class="slider-area2" style="height:200px;">
      <div class="slider-height2 hero-overly2 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="hero-cap hero-cap2 text-center">
                <h2>{{ TituloTopo }}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Hero End -->

    <div class="about-details" style="padding-top: 80px;">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="about-details-cap mb-50">
              <h4>{{ Titulo }}</h4>
              <p v-html="Texto"></p>
            </div>
          </div>
          <div class="col-lg-5">
            <ul id="thumbs" class="portfolio" style="display: inline-flex;">
              <!-- Item Project and Filter Name -->
              <li class="item-thumbs" v-for="produto in Dados.institucionalImg" :key="produto.institucionalImgId">
                <a class="hover-wrap" :title="produto.nomeProduto"
                  @click="mostraGaleria = !mostraGaleria; urlImg = $apiUrlIndex + produto.urlImagem;">
                  <img :src="$apiUrlIndex + produto.urlImagem" style="width: 100%;padding: 10px;border-radius: 15px;">
                </a>
              </li>
            </ul>
            <LightBox :media="[{ thumb: urlImg, src: urlImg }]" v-if="mostraGaleria"
              @onClosed="mostraGaleria = !mostraGaleria">
            </LightBox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import CallActionPages from '../shared/CallActionPages.vue';
import Header from '../shared/Header.vue'
import LightBox from 'vue-it-bigger'

export default {
  components: {
    Header,
    // CallActionPages,
    LightBox
  },
  metaInfo() {
        return {
            title: this.Titulo + ' - Services - Trevo International - Import and Export',
        }
    },
  data() {
    return {
      FundoMenu: '#000000',
      mostraGaleria: false,
      urlImg: '',
      CorDefault: '',
      CaminhoDefault: '',
      ServicoId: this.$route.params.id,
      Dados: [],
      Titulo: '',
      Texto: '',
      servicostopo: [],
      TituloTopo: '',
      imgServico: [
        {
          thumb: '',
          src: '',
          caption: ''
        }
      ],
    };
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/servicostopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.servicostopo = tiu2
        if (this.servicostopo != null) {
          this.TituloTopo = this.servicostopo[0].nome;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/" + parseInt(this.ServicoId, 10))
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Dados = tiu2;
        if (this.Dados != null) {
          this.Titulo = this.Dados.nome;
          this.Texto = this.Dados.resumo2;
        }
      });
  }
}
</script>

