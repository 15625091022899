import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import router from './router/routes'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueIframe from 'vue-iframes'
import Vue2Filters from 'vue2-filters'
import swal from "sweetalert";
import vSelect from 'vue-select'
import VueCarousel from 'vue-carousel';
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
import 'vue-select/dist/vue-select.css';
import 'vue-it-bigger/dist/vue-it-bigger.min.css';

Vue.component('v-select', vSelect)
Vue.use(VueCarousel);
Vue.use(VueResource)
Vue.use(require('vue-moment'))
Vue.use(VueIframe)
Vue.use(Vue2Filters)
Vue.component('Modal', VueModal)

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import { BootstrapVue} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)


//import jQuery from 'jquery'
//window.$ = jQuery

Vue.config.productionTip = false

Vue.prototype.$apiUrl = "https://controle.trevointl.com/api"
Vue.prototype.$apiUrlIndex = "https://controle.trevointl.com" 

//Vue.prototype.$Dadoslocal = "dadosMundoSite"
//Vue.prototype.$DadosCookie = "usuarioMundoSite"
//Vue.prototype.$ModeloSite = "ModeloMundoSite";
//Vue.prototype.$CaminhoImages = 'http://mundodosite.com.br/modelos/';


Vue.prototype.$redirect_reload = function(path) {
  this.$router.push(path);
  this.$router.go(this.$router.currentRoute)
}
Vue.prototype.$redirect = function(path) {
  this.$router.push(path)
}

Vue.prototype.$mensagem_sucesso = function (mensagem) {
  swal(" ", mensagem, "success");
};
Vue.prototype.$mensagem_normal = function (mensagem) {
  swal(mensagem);
};
Vue.prototype.$mensagem_erro = function (mensagem) {
  swal(" ", mensagem, "error");
};

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
