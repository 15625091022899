<template>
<header>
    <!-- Header Start -->
    <div class="header-area">
        <div class="main-header ">
            <div class="header-top d-none d-lg-block">
                <div class="container">
                  <div class="row">
                    <div class="col-xl-12">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="header-info-left">
                                <ul style="padding-left: 0rem !important;margin-bottom: 0rem !important;">     
                                    <li>{{ ItensTopo[0].referencia }}</li>
                                    <li>{{ ItensTopo[0].nome }}</li>
                                </ul>
                            </div>
                            <div class="header-info-right">
                                <ul class="header-social" style="margin-bottom: 0rem !important;">    
                                    <li v-if="facebook != ''"><a :href="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                    <li v-if="instagram != ''"><a :href="instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                    <li  v-if="linkedin != ''"><a :href="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li  v-if="youtube != ''"><a :href="youtube" target="_blank"><i class="fab fa-youtube"></i></a></li>
                                    <li  v-if="whatsapp != ''"><a :href="whatsapp" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="bg-top navbar-light">
                <div class="container">
                    <div class="row align-items-center">
                        <!-- Logo -->
                        <div class="col-xl-2 col-lg-2 hidden-xs hidden-sm mb-2 mt-2">
                            <router-link to="/" class="navbar-brand">
                              <img :src="imgLogo" :alt="altLogo" :style="{ height: AlturaLogo, width: LarguraLogo }" />
                            </router-link>
                        </div>
                        <div class="col-xl-10 col-lg-10 col-12">
                          <b-navbar-brand href="#" class="hidden-lg hidden-md" style="float: right;">
                            <router-link to="/" class="navbar-brand">
                              <img :src="imgLogo" :alt="altLogo" :style="{ height: AlturaLogo, width: LarguraLogo }" />
                            </router-link>
                          </b-navbar-brand>
                          <b-navbar toggleable="lg" class="menu-wrapper d-flex align-items-center justify-content-end ajusteposcel">
                            <div class="main-menu d-lg-block">
                              <b-navbar-toggle class="" target="nav-collapse" style="padding: 5px;"></b-navbar-toggle>
                              <b-collapse id="nav-collapse" is-nav>
                                <b-navbar-nav class="navbar-nav mr-auto">
                                  <b-nav-item v-for="item in ItensMenu" :key="item.institucionalId"  style="padding-left: 10px;" class="hrcel" :to="item.referencia">{{ item.nome }}</b-nav-item>
                                </b-navbar-nav>
                              </b-collapse>
                            </div>
                          </b-navbar>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

</template>


<script>
//import { Translator } from 'vue-google-translate';
export default {
  props: {
    corFundoMenu: {
      type: String
    }
  },
  components: {
    //Translator
  },
  data() {
    return {
      CorDefault: '',
      CaminhoDefault: '',
      mostraTopo: false,
      Retorno: [],
      imgLogo: '',
      altLogo: '',
      AlturaLogo: '',
      LarguraLogo: '',
      ItensMenu: [],
      ItensTopo: [],
      showModal: false,
      arrayOfCountries: [
        {
          code: "en|en",
          title: "English",
        },
        {
          code: "en|es",
          title: "Spanish",
        },
        {
          code: 'en|pt',
          title: 'Portuguese',
        },
      ],
      redes: [],
      facebook: '',
      instagram: '',
      linkedin: '',
      youtube: '',
      whatsapp: '',
      LinkWhats: '',
      imgWhats: '',
    };
  },
  directives: {
    scroll
  },
  methods: {
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/logotopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.Retorno = tiu2;
        if (this.Retorno != null) {
          if (this.Retorno[0].institucionalImg != null) {
            this.imgLogo = this.$apiUrlIndex + this.Retorno[0].institucionalImg[0].urlImagem;
            this.altLogo = this.Retorno[0].nome;
            this.AlturaLogo = this.Retorno[0].campoExtra1;
            this.LarguraLogo = this.Retorno[0].campoExtra2;
          }
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/itensmenu")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensMenu = tiu2.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) {
            return 1;
          }
          if (a.ordem < b.ordem) {
            return -1;
          }
          return 0;
        });
      });
    this.$http
      .get(this.$apiUrl + "/institucional/telas/itenstopo")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.ItensTopo = tiu2;
      });

      this.$http
      .get(this.$apiUrl + "/institucional/telas/redes")
      .then((res2) => res2.json())
      .then((tiu2) => {
        this.redes = tiu2;
        if (this.redes != null) {
          this.facebook = this.redes[0].nome;
          this.instagram = this.redes[0].referencia;
          this.linkedin = this.redes[0].campoExtra1;
          this.youtube = this.redes[0].campoExtra2;
          this.whatsapp = this.redes[0].campoExtra3;
          this.LinkWhats = this.redes[0].campoExtra4;
          if (this.redes[0].institucionalImg != null) {
            this.imgWhats = this.$apiUrlIndex + this.redes[0].institucionalImg[0].urlImagem;
          }
        }
      });
  },
  destroyed() {
  }
}
</script>

<style>
/* .navbar-light .navbar-nav .nav-link {
  color: #fff !important;
} */

.navbar-light .navbar-toggler {
  border-color: #cccccc !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

@media (max-width:991px) {
  .fundoCor {
    background-color: #000000;
    padding: 15px;
  }
}
@media (max-width:767px) {
.ajusteposcel{
  float: left;
}
/* 
.hrcel{
  border-bottom: 1px solid #cccccc;
} */
}
</style>